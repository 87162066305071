import { MigrateMessageSources } from './__generated__/MigrateMessageSources'
import { MigrateMessageSourcesInputV2 } from '../../__generated__/globalTypes'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation MigrateMessageSources($input: MigrateMessageSourcesInputV2!) {
    migrateMessageSourcesV2(input: $input) {
      success
    }
  }
`

export const useMigrateMessageSources = () => useMutation<MigrateMessageSources, MigrateMessageSourcesInputV2>(MUTATION)
