import { UpdateMarketplaceGallery, UpdateMarketplaceGalleryVariables } from './__generated__/UpdateMarketplaceGallery'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation UpdateMarketplaceGallery($input: SaveMarketplaceGalleryInputV2!) {
    updateMarketplaceGalleryV2(input: $input) {
      ... on MarketplaceGalleryErrorV2 {
        type
        error
      }
      ... on MarketplaceGalleryV2 {
        id
        items {
          id
          image
          marketplaceListingId
          description
        }
      }
    }
  }
`

export const useUpdateMarketplaceGallery = () => {
  return useMutation<UpdateMarketplaceGallery, UpdateMarketplaceGalleryVariables>(MUTATION)
}
