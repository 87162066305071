import {
  UploadMarketplaceListingFeatureImage,
  UploadMarketplaceListingFeatureImageVariables
} from './__generated__/UploadMarketplaceListingFeatureImage'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation UploadMarketplaceListingFeatureImage($marketplaceListingId: String!) {
    uploadMarketplaceFeatureImageV2(input: { marketplaceListingId: $marketplaceListingId }) {
      __typename
      ... on MarketplaceGalleryErrorV2 {
        type
        error
      }
      ... on MarketplaceGalleryUploadV2 {
        uploadUrl
      }
    }
  }
`

export const useGetUploadMarketplaceListingFeatureImageUrl = () => {
  return useMutation<UploadMarketplaceListingFeatureImage, UploadMarketplaceListingFeatureImageVariables>(MUTATION)
}
