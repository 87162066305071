import { MigrateBoard, MigrateBoardVariables } from './__generated__/MigrateBoard'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation MigrateBoard($boardId: String!) {
    migrateBoardV2(boardId: $boardId) {
      message
      success
    }
  }
`

export const useMigrateBoard = () => useMutation<MigrateBoard, MigrateBoardVariables>(MUTATION)
