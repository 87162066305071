import { MigratePerson, MigratePersonVariables } from './__generated__/MigratePerson'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation MigratePerson($personId: String!) {
    migratePersonV2(personId: $personId) {
      message
      success
    }
  }
`

export const useMigratePerson = () => useMutation<MigratePerson, MigratePersonVariables>(MUTATION)
