import { AcceptedReferralSortBy, AcceptedReferralSortDirection } from '../types'
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank, CloudDownload } from '@material-ui/icons'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import React, { useCallback, useState } from 'react'
import { SortDirection, SortableTableCell } from '../../../ui/SortableTableCell'
import { csvDownload, csvFormat } from '../../../utils'

import { Alert } from '@material-ui/lab'
import { ApolloConsumer } from '@apollo/react-hooks'
import { CsvImportButton } from '../../../ui/CsvImportButton'
import { FormattedDateTime } from '../../formatted-date-time'
import InfiniteScroll from 'react-infinite-scroller'
import { SearchInput } from '../../../ui/SearchInput'
import { TruncatedId } from '../../truncated-id'
import moment from 'moment'
import { useListAcceptedReferrals } from '../hooks/useListAcceptedReferrals'
import { useUpdateAcceptedReferral } from '../hooks/useUpdateAcceptedRefferal'

const useStyles = makeStyles({
  tableHeader: {
    whiteSpace: 'nowrap'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  }
})

const PER_PAGE = 50

export const ListReferrals = () => {
  const classes = useStyles()
  const [searchTerm, setSearchTerm] = useState<string | null>(null)
  const [sortDirection, setSortDirection] = useState<AcceptedReferralSortDirection>(AcceptedReferralSortDirection.desc)
  const [sortBy, setSortBy] = useState<AcceptedReferralSortBy>(AcceptedReferralSortBy.createdAt)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [updateAcceptedReferral] = useUpdateAcceptedReferral()

  const input = {
    sortDirection,
    sortBy,
    searchTerm,
    limit: PER_PAGE
  }

  const { data, loading, error, fetchMore } = useListAcceptedReferrals({
    input
  })

  const loadNextPage = useCallback(async () => {
    if (isLoadingMore || !data?.listAcceptedReferrals?.nextCursor) {
      return
    }

    setIsLoadingMore(true)
    return await fetchMore({
      updateQuery(previousQueryResult, options) {
        const newSearch = options.fetchMoreResult?.listAcceptedReferrals.acceptedReferrals || []
        const previousSearch = previousQueryResult.listAcceptedReferrals.acceptedReferrals || []

        return {
          listAcceptedReferrals: {
            ...previousQueryResult.listAcceptedReferrals,
            nextCursor: options.fetchMoreResult?.listAcceptedReferrals.nextCursor || null,
            acceptedReferrals: [...previousSearch, ...newSearch]
          }
        }
      },
      variables: {
        input: {
          ...input,
          cursor: data?.listAcceptedReferrals?.nextCursor || null
        }
      }
    }).then(res => {
      setIsLoadingMore(false)
      return res
    })
  }, [data, fetchMore, input, isLoadingMore])

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Referrals</Typography>
        <FlexHorizontal>
          <CsvImportButton
            mappings={[
              'id',
              'notified',
              'createdAt',
              'purchaseId',
              'invalidated',
              'shopifyOrderId',
              'shopifyOrderName',
              'shopifyCustomerName',
              'shopifyCustomerEmail',
              'referrerPersonId',
              'referralCode',
              'referrerName',
              'referrerEmail',
              'orderDeliveredAt',
              'creditRedeemed',
              'creditType',
              '__typename'
            ]}
            types={{
              id: 'String',
              notified: 'NullableDate',
              createdAt: 'Date',
              creditRedeemed: 'Boolean',
              creditType: 'NullableString',
              orderDeliveredAt: 'NullableDate'
            }}
            handleImport={async (
              data: {
                id: string
                notified?: string
                createdAt: string
                creditRedeemed?: string
                creditType?: string
                orderDeliveredAt?: string
              }[]
            ) => {
              await Promise.all(
                data.map(async item => {
                  await updateAcceptedReferral({
                    variables: {
                      input: {
                        id: item.id,
                        notified: item.notified ? moment(item.notified).toDate().getTime() : null,
                        createdAt: moment(item.createdAt).toDate().getTime(),
                        creditRedeemed: item.creditRedeemed === 'Yes',
                        creditType: item.creditType,
                        orderDeliveredAt: item.orderDeliveredAt
                          ? moment(item.orderDeliveredAt).toDate().getTime()
                          : null
                      }
                    }
                  })
                })
              )
            }}
          />
          <div style={{ width: 12 }} />
          <ApolloConsumer>
            {apollo => {
              const buildPaginationPage = async (
                prev: any[],
                cursor: string | null,
                retries: number
              ): Promise<any[]> => {
                const paginationInput = {
                  ...input,
                  cursor
                }

                try {
                  const result = await apollo.query({
                    query: useListAcceptedReferrals.QUERY,
                    variables: {
                      input: paginationInput
                    }
                  })

                  const dataResult = [...prev, ...result.data.listAcceptedReferrals.acceptedReferrals]

                  if (!result.data.listAcceptedReferrals.nextCursor) {
                    return dataResult
                  }

                  return await buildPaginationPage(dataResult, result.data.listAcceptedReferrals.nextCursor, 0)
                } catch (e) {
                  if (retries < 5) {
                    return await buildPaginationPage(prev, cursor, retries + 1)
                  } else {
                    throw new Error('Out of retries')
                  }
                }
              }

              return (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    const results = await buildPaginationPage([], null, 0)
                    const csv = csvFormat(
                      results.map(item => ({
                        ...item,
                        orderDeliveredAt: item.orderDeliveredAt
                          ? moment(item.orderDeliveredAt).format('YYYY-MM-DD')
                          : null,
                        createdAt: moment(item.createdAt).format('YYYY-MM-DD'),
                        notified: item.notified ? moment(item.notified).format('YYYY-MM-DD') : null,
                        creditRedeemed: item.creditRedeemed ? 'Yes' : 'No'
                      })),
                      Object.keys(results[0])
                    )

                    csvDownload(csv, 'customer-referrals')
                  }}
                  color='default'
                  endIcon={<CloudDownload />}>
                  Export CSV
                </Button>
              )
            }}
          </ApolloConsumer>
        </FlexHorizontal>
        <SearchInput onSearch={setSearchTerm} />
      </FlexHorizontal>
      <Spacer size='large' />
      {loading && !data?.listAcceptedReferrals.acceptedReferrals ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading the accepted referrals</Alert>
      ) : !data?.listAcceptedReferrals.acceptedReferrals.length ? (
        <Alert severity='info'>There are no accepted referrals</Alert>
      ) : (
        <>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadNextPage}
            hasMore={!!data?.listAcceptedReferrals?.nextCursor}
            loader={
              <Box>
                <Spacer size='large' />
                <CircularProgress />
              </Box>
            }>
            <Card key='card'>
              <TableContainer
                style={{
                  maxWidth: 'calc(100vw - 290px)',
                  overflowX: 'auto'
                }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>ID</TableCell>
                      <SortableTableCell
                        direction={SortDirection[sortDirection]}
                        active={sortBy === AcceptedReferralSortBy.createdAt}
                        width={120}
                        onClick={direction => {
                          setSortDirection(AcceptedReferralSortDirection[direction])
                          setSortBy(AcceptedReferralSortBy.createdAt)
                        }}>
                        Referral Date
                      </SortableTableCell>
                      <TableCell className={classes.tableHeader}>Shopify Order ID</TableCell>
                      <SortableTableCell
                        direction={SortDirection[sortDirection]}
                        active={sortBy === AcceptedReferralSortBy.shopifyOrderName}
                        width={120}
                        onClick={direction => {
                          setSortDirection(AcceptedReferralSortDirection[direction])
                          setSortBy(AcceptedReferralSortBy.shopifyOrderName)
                        }}>
                        Order Name
                      </SortableTableCell>
                      <SortableTableCell
                        direction={SortDirection[sortDirection]}
                        active={sortBy === AcceptedReferralSortBy.shopifyCustomerName}
                        width={120}
                        onClick={direction => {
                          setSortDirection(AcceptedReferralSortDirection[direction])
                          setSortBy(AcceptedReferralSortBy.shopifyCustomerName)
                        }}>
                        Customer Name
                      </SortableTableCell>
                      <SortableTableCell
                        direction={SortDirection[sortDirection]}
                        active={sortBy === AcceptedReferralSortBy.shopifyCustomerEmail}
                        width={120}
                        onClick={direction => {
                          setSortDirection(AcceptedReferralSortDirection[direction])
                          setSortBy(AcceptedReferralSortBy.shopifyCustomerEmail)
                        }}>
                        Customer Email
                      </SortableTableCell>
                      <TableCell className={classes.tableHeader}>Referrer ID</TableCell>
                      <SortableTableCell
                        direction={SortDirection[sortDirection]}
                        active={sortBy === AcceptedReferralSortBy.referrerName}
                        width={120}
                        onClick={direction => {
                          setSortDirection(AcceptedReferralSortDirection[direction])
                          setSortBy(AcceptedReferralSortBy.referrerName)
                        }}>
                        Referrer Name
                      </SortableTableCell>
                      <SortableTableCell
                        direction={SortDirection[sortDirection]}
                        active={sortBy === AcceptedReferralSortBy.referrerEmail}
                        width={120}
                        onClick={direction => {
                          setSortDirection(AcceptedReferralSortDirection[direction])
                          setSortBy(AcceptedReferralSortBy.referrerEmail)
                        }}>
                        Referrer Email
                      </SortableTableCell>
                      <SortableTableCell
                        direction={SortDirection[sortDirection]}
                        active={sortBy === AcceptedReferralSortBy.orderDeliveredAt}
                        width={120}
                        onClick={direction => {
                          setSortDirection(AcceptedReferralSortDirection[direction])
                          setSortBy(AcceptedReferralSortBy.orderDeliveredAt)
                        }}>
                        Order Delivered On
                      </SortableTableCell>
                      <SortableTableCell
                        direction={SortDirection[sortDirection]}
                        active={sortBy === AcceptedReferralSortBy.notified}
                        width={120}
                        onClick={direction => {
                          setSortDirection(AcceptedReferralSortDirection[direction])
                          setSortBy(AcceptedReferralSortBy.notified)
                        }}>
                        Notified On
                      </SortableTableCell>
                      <SortableTableCell
                        direction={SortDirection[sortDirection]}
                        active={sortBy === AcceptedReferralSortBy.invalidated}
                        width={120}
                        onClick={direction => {
                          setSortDirection(AcceptedReferralSortDirection[direction])
                          setSortBy(AcceptedReferralSortBy.invalidated)
                        }}>
                        Invalidated
                      </SortableTableCell>
                      <SortableTableCell
                        direction={SortDirection[sortDirection]}
                        active={sortBy === AcceptedReferralSortBy.creditRedeemed}
                        width={120}
                        onClick={direction => {
                          setSortDirection(AcceptedReferralSortDirection[direction])
                          setSortBy(AcceptedReferralSortBy.creditRedeemed)
                        }}>
                        Credit Redeemed
                      </SortableTableCell>
                      <TableCell className={classes.tableHeader}>Credit Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.listAcceptedReferrals.acceptedReferrals.map(acceptedReferral =>
                      acceptedReferral.__typename === 'AcceptedReferralV2' ? (
                        <TableRow key={acceptedReferral.id}>
                          <TableCell className={classes.tableCell}>
                            <TruncatedId value={acceptedReferral.id} full path={`/referrals/${acceptedReferral.id}`} />
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <FormattedDateTime value={acceptedReferral.createdAt} format='YYYY-MM-DD' />
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <a
                              style={{
                                color: '#3f51b5',
                                fontSize: '1rem',
                                textDecoration: 'none'
                              }}
                              target='_blank'
                              rel='noopener noreferrer'
                              href={`https://vestaboard-plus.myshopify.com/admin/orders/${acceptedReferral?.shopifyOrderId}`}>
                              {acceptedReferral?.shopifyOrderId || ''}
                            </a>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {acceptedReferral?.shopifyOrderName || ''}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {acceptedReferral?.shopifyCustomerName || ''}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {acceptedReferral?.shopifyCustomerEmail || ''}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <TruncatedId
                              value={acceptedReferral.referrerPersonId}
                              full
                              path={`/person/${acceptedReferral.referrerPersonId}`}
                            />
                          </TableCell>
                          <TableCell className={classes.tableCell}>{acceptedReferral.referrerName}</TableCell>
                          <TableCell className={classes.tableCell}>{acceptedReferral.referrerEmail}</TableCell>
                          <TableCell className={classes.tableCell}>
                            {acceptedReferral.orderDeliveredAt ? (
                              <FormattedDateTime value={acceptedReferral.orderDeliveredAt} format='YYYY-MM-DD' />
                            ) : null}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {acceptedReferral.notified ? (
                              <FormattedDateTime value={acceptedReferral.notified} format='YYYY-MM-DD' />
                            ) : null}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <ButtonBase
                              onClick={() => {
                                const invalidated = !acceptedReferral.invalidated ? new Date().getTime() : null
                                updateAcceptedReferral({
                                  variables: {
                                    input: {
                                      id: acceptedReferral.id,
                                      invalidated
                                    }
                                  },
                                  optimisticResponse: {
                                    updateAcceptedReferral: {
                                      ...acceptedReferral,
                                      invalidated
                                    }
                                  }
                                })
                              }}>
                              {acceptedReferral.invalidated ? <CheckBox /> : <CheckBoxOutlineBlank />}
                            </ButtonBase>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <ButtonBase
                              onClick={() => {
                                const creditRedeemed = !acceptedReferral.creditRedeemed
                                updateAcceptedReferral({
                                  variables: {
                                    input: {
                                      id: acceptedReferral.id,
                                      creditRedeemed
                                    }
                                  },
                                  optimisticResponse: {
                                    updateAcceptedReferral: {
                                      ...acceptedReferral,
                                      creditRedeemed
                                    }
                                  }
                                })
                              }}>
                              {acceptedReferral.creditRedeemed ? <CheckBox /> : <CheckBoxOutlineBlank />}
                            </ButtonBase>
                          </TableCell>
                          <TableCell>{acceptedReferral.creditType || ''}</TableCell>
                        </TableRow>
                      ) : null
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </InfiniteScroll>
        </>
      )}
    </Box>
  )
}
