import { BulkTagMessages, BulkTagMessagesVariables } from './__generated__/BulkTagMessages'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation BulkTagMessages($messageIds: [String!]!, $tags: [String!]!) {
    tagMessages: tagMessagesV2(messageIds: $messageIds, tags: $tags) {
      ... on MessageV2 {
        id
        tags
      }
      ... on MessageErrorV2 {
        type
        error
      }
    }
  }
`

export const useBulkTagMessages = () => {
  return useMutation<BulkTagMessages, BulkTagMessagesVariables>(MUTATION)
}
