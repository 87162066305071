import { Button, makeStyles } from '@material-ui/core'
import React, { useRef, useState } from 'react'

import { QRCodeSVG } from 'qrcode.react'
import { Spacer } from '@vestaboard/installables'

interface IReferralQrCode {
  vbref: string
}

const useStyles = makeStyles({
  container: {
    cursor: 'pointer',
    width: 100,
    height: 100,
    transition: 'width 0.5s, height 0.5s'
  },
  containerExpanded: {
    cursor: 'pointer',
    width: 600,
    height: 600
  },
  wrapper: {
    transform: 'scale(0.2) translate(-200%, -200%)',
    transition: 'transform 0.5s',
    '&:hover': {
      transform: 'scale(0.21) translate(-200%, -200%) rotate(20deg)'
    }
  },
  wrapperExpanded: {
    transform: 'scale(1) translate(0%, 0%)',
    '&:hover': {
      transform: 'scale(1) translate(0%, 0%)'
    }
  }
})

export const ReferralQrCode = (props: IReferralQrCode) => {
  const classes = useStyles()
  const containerRef = useRef<SVGSVGElement | null>(null)
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <div
        className={expanded ? [classes.container, classes.containerExpanded].join(' ') : classes.container}
        onClick={() => {
          setExpanded(!expanded)
        }}>
        <div className={expanded ? [classes.wrapper, classes.wrapperExpanded].join(' ') : classes.wrapper}>
          <QRCodeSVG
            ref={containerRef}
            width={600}
            height={600}
            bgColor='#fff'
            value={`https://web.vestaboard.com/referral?vbref=${props.vbref}`}
            level='H'
            imageSettings={{
              src: 'https://www.vestaboard.com/hubfs/Assets/icons/logo-v-svg.svg',
              height: 40,
              width: 40,
              excavate: true
            }}
          />
        </div>
      </div>
      {expanded ? (
        <>
          <Spacer size='large' />
          <Button
            variant='text'
            onClick={() => {
              if (containerRef.current === null) {
                return
              }
              const preface = '<?xml version="1.0" standalone="no"?>\r\n'
              containerRef.current.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
              const svgData = containerRef.current.outerHTML
              const svgBlob = new Blob([`${preface}${svgData}`], { type: 'image/svg+xml;charset=utf-8' })
              const svgUrl = URL.createObjectURL(svgBlob)
              const downloadLink = document.createElement('a')
              downloadLink.href = svgUrl
              downloadLink.download = 'vbref.svg'
              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            }}>
            Download
          </Button>
        </>
      ) : null}
    </>
  )
}
