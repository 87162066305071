import { Box, Button, Chip, CircularProgress, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'

import { Alert } from '@material-ui/lab'
import { Spacer } from '@vestaboard/installables'

interface IBulkTags {
  onSave: (tags: string[]) => void
  onCancel: () => void
}

export const BulkTags = (props: IBulkTags) => {
  const [tags, setTags] = useState<string[]>([])
  const [text, setText] = useState('')
  const [isDirty, setIsDirty] = useState(false)
  const [saving, setSaving] = useState(false)

  return (
    <Box
      style={{
        overflow: 'auto',
        backgroundColor: 'white',
        padding: '24px'
      }}>
      <Alert severity='info'>These tags will be added to every message in the message set.</Alert>
      <Spacer size='small' />
      <Typography variant='h5'>Tags</Typography>
      <Spacer size='small' />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}>
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => {
              setIsDirty(true)
              setTags(tags.filter(t => t !== tag))
            }}
            style={{
              margin: 4
            }}
          />
        ))}
        <TextField
          style={{
            marginLeft: 4
          }}
          value={text}
          variant='outlined'
          size='small'
          placeholder='Message Tags'
          onChange={e => {
            if (e.target.value.includes(',')) {
              setTags([...tags, ...text.split(',').map(tag => tag.trim())].filter(tag => tag !== ''))
              setText('')
              setIsDirty(true)
            } else {
              setText(e.target.value)
            }
          }}
          onBlur={() => {
            if (text !== '') {
              setIsDirty(true)
            }
            setTags([...tags, ...text.split(',').map(tag => tag.trim())].filter(tag => tag !== ''))
            setText('')
          }}
        />
        {isDirty ? (
          <Button
            style={{
              marginLeft: 8
            }}
            size='small'
            variant='contained'
            color='primary'
            disabled={saving}
            onClick={() => {
              setSaving(true)
              props.onSave(tags)
            }}>
            {saving ? <CircularProgress /> : 'Tag All Messages'}
          </Button>
        ) : null}
      </Box>
    </Box>
  )
}
