import { MigrateDevice, MigrateDeviceVariables } from './__generated__/MigrateDevice'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation MigrateDevice($deviceId: String!) {
    migrateFlagshipDeviceV2(deviceId: $deviceId) {
      message
      success
    }
  }
`

export const useMigrateDevice = () => useMutation<MigrateDevice, MigrateDeviceVariables>(MUTATION)
