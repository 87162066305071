import * as React from 'react'

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core'
import { PersonDetailQuery, PersonDetailQueryVariables } from './__generated__/PersonDetailQuery'
import { Spacer, useToasts } from '@vestaboard/installables'
import {
  UpdateEmailAddressMutation,
  UpdateEmailAddressMutationVariables
} from './__generated__/UpdateEmailAddressMutation'
import { UpdatePersonMutation, UpdatePersonMutationVariables } from './__generated__/UpdatePersonMutation'
import { useHistory, useParams } from 'react-router'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { Alert } from '@material-ui/lab'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { CountryCode } from './CountryCode'
import { FormattedDateTime } from '../formatted-date-time'
import { Helmet } from 'react-helmet'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { Link } from '../link'
import { ReferralQrCode } from './ReferralQrCode'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import { TruncatedId } from '../truncated-id'
import { gql } from 'apollo-boost'
import { useMigratePerson } from '../../api'

type PersonDetailPageProps = IPersonDetailPageProps

interface IPersonDetailPageProps {}

const MUTATION = gql`
  mutation UpdatePersonMutation($personId: String!, $firstName: String!, $lastName: String!) {
    updatePerson(input: { id: $personId, name: { firstName: $firstName, lastName: $lastName } }) {
      updated
      person {
        id
      }
    }
  }
`

const UPDATE_EMAIL_ADDRESS_MUTATION = gql`
  mutation UpdateEmailAddressMutation($accountId: String!, $newEmailAddress: String!) {
    updateEmailAddress(input: { accountId: $accountId, newEmailAddress: $newEmailAddress }) {
      id
    }
  }
`

const QUERY = gql`
  query PersonDetailQuery($personId: String!) {
    person(id: $personId) {
      id
      firstName
      lastName
      account {
        id
        emailAddress
        purchases {
          created
          code
        }
      }
      referralCode {
        id
        code
      }
      tenantAssociations(onlyCurrent: false) {
        id
        role
        created
        invitationStatus
        isCurrentMember
        tenant {
          id
          boards {
            id
            title
            created
            devices {
              id
              latestLocationCountryCode
            }
          }
        }
      }
    }
  }
`

const useStyles = makeStyles({
  title: {
    paddingBottom: 14
  },
  personTitle: {
    paddingTop: 32,
    paddingBottom: 14
  }
})

export const BooleanIcon = (props: { value: boolean }) => {
  if (props.value === true) {
    return <CheckCircleIcon color={'primary'} />
  } else if (props.value === false) {
    return <HighlightOffIcon color={'disabled'} />
  } else {
    return <Box />
  }
}

export const PersonDetailPage: React.FC<PersonDetailPageProps> = props => {
  const classes = useStyles()
  const history = useHistory()
  const params = useParams<{ id: string }>()

  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [emailChanging, setEmailChanging] = React.useState(false)
  const [newEmail, setNewEmail] = React.useState('')
  const [newEmailSaving, setNewEmailSaving] = React.useState(false)
  const [newEmailError, setNewEmailError] = React.useState('')
  const { addToast } = useToasts()
  const [migratePerson] = useMigratePerson()

  const [updateEmailAddressMutation] = useMutation<UpdateEmailAddressMutation, UpdateEmailAddressMutationVariables>(
    UPDATE_EMAIL_ADDRESS_MUTATION
  )

  const [mutate] = useMutation<UpdatePersonMutation, UpdatePersonMutationVariables>(MUTATION)

  const { data, loading, refetch } = useQuery<PersonDetailQuery, PersonDetailQueryVariables>(QUERY, {
    variables: {
      personId: params.id
    }
  })

  React.useEffect(() => {
    setFirstName(data?.person.firstName ?? '')
    setLastName(data?.person.lastName ?? '')
  }, [data])

  const saveEmail = async () => {
    if (data?.person?.account?.id) {
      setNewEmailSaving(true)
      setNewEmailError('')
      try {
        await updateEmailAddressMutation({
          variables: {
            accountId: data.person.account.id,
            newEmailAddress: newEmail
          }
        })
        setNewEmailSaving(false)
        setEmailChanging(false)
        setNewEmail('')
        refetch()
      } catch (error) {
        setNewEmailError(
          'Auth0 response - could not update - this user might be using a third-party identity like an Apple or Google account.'
        )
      }
    }
  }

  const cancelSaveEmail = () => {
    setNewEmailError('')
    setNewEmailSaving(false)
    setEmailChanging(false)
    setNewEmail('')
  }

  const savePerson = async () => {
    if (firstName && lastName) {
      await mutate({
        variables: {
          personId: params.id,
          firstName,
          lastName
        }
      })
      refetch()
    }
  }

  if (loading) return <CircularProgress />

  if (!data) return <Alert severity='error'>Person not found.</Alert>

  const name =
    data.person.firstName && data.person.lastName
      ? `${data.person.firstName} ${data.person.lastName}`
      : data.person.account.emailAddress

  const boardRows = data.person.tenantAssociations
    .sort((a, b) => (b.isCurrentMember ? 1 : 0) - (a.isCurrentMember ? 1 : 0))
    .flatMap(ta => ({
      deviceId: ta.tenant.boards[0]?.devices?.[0]?.id,
      boardId: ta.tenant.boards[0]?.id,
      boardCreated: ta.tenant.boards[0]?.created,
      boardTitle: ta.tenant.boards[0]?.title,
      tenantCreated: ta.created,
      tenantRole: ta.role,
      countryCode: ta.tenant.boards[0]?.devices[0]?.latestLocationCountryCode,
      isCurrentMember: ta.isCurrentMember
    }))
    .filter(row => !!row.boardId)

  const isEligibleForReferralCode = !!boardRows.filter(board => board.countryCode === 'US').length

  return (
    <Box>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          Person - {data?.person.firstName ?? ''} {data?.person.lastName ?? ''} - Detail Page - Vestaboard Superadmin
        </title>
      </Helmet>
      <Typography variant='button' display='block' gutterBottom>
        <Link to='/person'>Person</Link>
      </Typography>
      <Typography variant='h4' className={classes.title}>
        {name}
      </Typography>
      <Box>
        <Grid container>
          <Grid item md={6} sm={12}>
            <Card>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Person ID</strong>
                    </TableCell>
                    <TableCell>{data.person.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>First Name</strong>
                    </TableCell>
                    <TableCell>
                      <TextField
                        size='small'
                        variant='outlined'
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Last Name</strong>
                    </TableCell>
                    <TableCell>
                      <TextField
                        size='small'
                        variant='outlined'
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Email Address</strong>
                    </TableCell>
                    <TableCell>
                      {data.person.account.emailAddress}
                      <Button onClick={() => setEmailChanging(true)} variant='outlined' color='primary' size='small'>
                        Change
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Referral code</strong>
                    </TableCell>
                    <TableCell>
                      {isEligibleForReferralCode ? <code>{data.person.referralCode.code}</code> : 'Not in USA'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Purchases</strong>
                    </TableCell>
                    <TableCell>
                      <Table>
                        {data.person.account.purchases.map(p => (
                          <TableRow key={p.code}>
                            <TableCell>
                              <FormattedDateTime value={p.created}></FormattedDateTime>
                            </TableCell>
                            <TableCell>
                              <code>{p.code}</code>
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
            <Spacer size='large' />
            <Button
              size='large'
              color='primary'
              onClick={() => savePerson()}
              disabled={!firstName || !lastName}
              variant='contained'>
              Save
            </Button>
          </Grid>
        </Grid>
        {data?.person?.referralCode?.code ? (
          <>
            <Spacer size='extraLarge' />
            <Typography variant='h5'>Referral QR Code</Typography>
            <Spacer size='small' />
            <ReferralQrCode vbref={data.person.referralCode.code as string} />
          </>
        ) : null}
        <Spacer size='extraLarge' />
        <Grid container>
          <Grid item md={12}>
            <Typography variant='h5'>Boards</Typography>
            <Spacer size='small' />
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Board ID</TableCell>
                    <TableCell>Associated</TableCell>
                    <TableCell>Board Created</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Available In App</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {boardRows.map(
                    ({
                      deviceId,
                      boardId,
                      boardCreated,
                      boardTitle,
                      tenantCreated,
                      tenantRole,
                      countryCode,
                      isCurrentMember
                    }) => {
                      return (
                        <TableRow key={boardId}>
                          <TableCell>
                            <Button variant='outlined' onClick={() => history.push(`/boards/${boardId}`)}>
                              <TruncatedId value={boardId} />
                            </Button>
                          </TableCell>
                          <TableCell>
                            <FormattedDateTime value={tenantCreated} />
                          </TableCell>
                          <TableCell>
                            <FormattedDateTime value={boardCreated} />
                          </TableCell>
                          <TableCell>{boardTitle}</TableCell>
                          <TableCell>{tenantRole}</TableCell>
                          <TableCell>{!!deviceId ? 'Paired' : 'Virtual'}</TableCell>
                          <TableCell>
                            <Tooltip title='Add' aria-label='add'>
                              <BooleanIcon value={isCurrentMember} />
                            </Tooltip>
                          </TableCell>
                          <TableCell style={{ fontSize: 16 }}>
                            <CountryCode deviceId={deviceId} countryCode={countryCode} />
                          </TableCell>
                          <TableCell>{!isCurrentMember ? <Button>Unhide</Button> : null}</TableCell>
                        </TableRow>
                      )
                    }
                  )}
                </TableBody>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={emailChanging}
        onClose={() => setEmailChanging(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Update email address'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            This action will update the user's email address in Vestaboard and in Auth0.
          </DialogContentText>
          <Box>
            <TextField value={newEmail} onChange={e => setNewEmail(e.target.value)} placeholder='New email address' />
            {newEmailError ? <Typography color='error'>{newEmailError}</Typography> : null}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancelSaveEmail()} color='default'>
            Cancel
          </Button>
          <Button disabled={!newEmail || newEmailSaving} onClick={() => saveEmail()} color='primary' autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Spacer size='extraLarge' />
      <Button
        onClick={async () => {
          addToast('Migration task has been started', {
            appearance: 'success'
          })
          await migratePerson({ variables: { personId: params.id } })
        }}>
        <SyncAltIcon />
        <Typography>V2 Migrate</Typography>
      </Button>
    </Box>
  )
}
