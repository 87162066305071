import { BoardPreview, FlexHorizontal, FullModal, IBoard, Spacer, useToasts } from '@vestaboard/installables'
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { CloudDownload, DragIndicator, Label, RemoveCircle } from '@material-ui/icons'
import { CreateMessageModal, MessageModal, useCreateMessage } from '../../message-v2'
import React, { useCallback, useState } from 'react'

import { Alert } from '@material-ui/lab'
import { BulkTags } from '../../message-v2/components/BulkTags'
import { Checkbox } from '../../../ui/Checkbox'
import { CsvImportButton } from '../../../ui/CsvImportButton'
import { DraggableTableRow } from '../../../ui/DraggableTableRow'
import { DroppableTableBody } from '../../../ui/DropableTableBody'
import { DynamicMessages } from '../../message-set-v1/message-set-edit-container/DynamicMessages'
import { FormattedDateTime } from '../../formatted-date-time'
import { GetMessageSetV2_messageSet_MessageSetV2 } from '../hooks/__generated__/GetMessageSetV2'
import InfiniteScroll from 'react-infinite-scroller'
import { batch } from 'cartesian-js'
import { csvDownload } from '../../../utils'
import { uniq } from 'lodash'
import { useBulkTagMessages } from '../../message-v2/hooks/useBulkTagMessages'
import { useHistory } from 'react-router'

interface IMessageSetForm {
  messageSet?: GetMessageSetV2_messageSet_MessageSetV2
  onDelete?: () => void
  onSave: (input: { title: string; public: boolean; dynamic: boolean; rssUrl?: string; messageIds: string[] }) => void
}

interface IMessage {
  id: string
  text: string | null
  characters: number[][] | null
  createdAt: number
  tags: string[] | null
}

const deduplicateMessages = (messages: IMessage[]) => {
  const deduplicatedMessages: IMessage[] = []

  messages.forEach(message => {
    const foundMessage = deduplicatedMessages.find(deduplicatedMessage => {
      return deduplicatedMessage.id === message.id
    })

    if (!foundMessage) {
      deduplicatedMessages.push(message)
    }
  })

  return deduplicatedMessages
}

export const MessageSetForm = (props: IMessageSetForm) => {
  const [messageId, setMessageId] = useState<string | null>(null)
  const [createMessage] = useCreateMessage()
  const [title, setTitle] = useState(props.messageSet?.title || '')
  const [isPublic, setPublic] = useState(props.messageSet?.public || false)
  const [dynamic, setDynamic] = useState(props.messageSet?.dynamic || false)
  const [rssUrl, setRssUrl] = useState(props.messageSet?.rssUrl || '')
  const [messages, setMessages] = useState<IMessage[]>(props.messageSet?.messages || [])
  const [error, setError] = useState<string | null>(null)
  const history = useHistory()
  const [isAddingMessage, setIsAddingMessage] = useState(false)
  const { addToast } = useToasts()
  const [messagesToShow, setMessagesToShow] = useState(25)
  const [bulkTagging, setBulkTagging] = useState(false)
  const [bulkTagMessagesMutation] = useBulkTagMessages()

  const handleSave = () => {
    setError(null)

    if (!title) {
      setError('title')
      addToast('Please enter a title', {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }

    if (dynamic && !rssUrl) {
      setError('rssUrl')
      addToast('Please enter an RSS URL', {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }

    props.onSave({
      title,
      public: isPublic,
      dynamic,
      rssUrl,
      messageIds: messages.map(message => message.id)
    })
  }

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return
      }

      const startIndex = result.source.index
      const endIndex = result.destination.index

      setMessages(messages => {
        const newMessages = Array.from(messages)
        const [removed] = newMessages.splice(startIndex, 1)
        newMessages.splice(endIndex, 0, removed)
        return deduplicateMessages(newMessages)
      })
    },
    [setMessages]
  )

  return (
    <>
      {bulkTagging ? (
        <FullModal onClose={() => setBulkTagging(false)} title='Bulk Tag'>
          <BulkTags
            onCancel={() => setBulkTagging(false)}
            onSave={async tagsUnformatted => {
              const tags = tagsUnformatted.map(tag => tag.trim().toLowerCase())
              setMessages(messages =>
                messages.map(message => ({ ...message, tags: uniq([...(message.tags || []), ...tags]) }))
              )
              await bulkTagMessagesMutation({
                variables: {
                  messageIds: messages.map(message => message.id),
                  tags
                }
              })
              setBulkTagging(false)
            }}
          />
        </FullModal>
      ) : null}
      <MessageModal
        messageId={messageId}
        onClose={() => {
          setMessageId(null)
        }}
        onUpdateTags={(messageId, tags) => {
          setMessages(messages =>
            messages.map(message => {
              if (message.id === messageId) {
                return {
                  ...message,
                  tags
                }
              }
              return message
            })
          )
          setMessageId(null)
        }}
      />
      {isAddingMessage ? (
        <CreateMessageModal
          onClose={() => {
            setIsAddingMessage(false)
          }}
          onSaved={message => {
            setMessages(deduplicateMessages([message, ...messages]))
          }}
        />
      ) : null}
      <Card component={Paper}>
        <Box padding={'24px'}>
          <TextField
            label='Title'
            value={title}
            onChange={e => setTitle(e.target.value)}
            fullWidth
            variant='outlined'
            error={error === 'title'}
          />
          <Spacer size='medium' />
          <Checkbox
            checked={isPublic}
            handleCheck={() => {
              setPublic(!isPublic)
            }}
            label='Public'
          />
          <Spacer size='medium' />
          <Checkbox
            checked={dynamic}
            handleCheck={() => {
              setDynamic(!dynamic)
            }}
            label='Dynamic'
          />
          {dynamic ? (
            <>
              <Spacer size='medium' />
              <TextField
                error={error === 'rssUrl'}
                label='RSS URL'
                value={rssUrl}
                onChange={e => setRssUrl(e.target.value)}
                fullWidth
                variant='outlined'
              />
            </>
          ) : null}
          <Spacer size='large' />
          <FlexHorizontal>
            <Button
              onClick={() => {
                history.push('/message-sets')
              }}>
              Cancel
            </Button>
            <Box width={24} />
            <Button onClick={handleSave} variant='contained' color='primary' size='large'>
              Save
            </Button>
            <Box width={24} />
            {props.onDelete ? (
              <Button onClick={props.onDelete} variant='contained' color='secondary' size='large'>
                Delete
              </Button>
            ) : null}
          </FlexHorizontal>
        </Box>
      </Card>
      <Spacer size='extraLarge' />
      <FlexHorizontal spaceBetween={true}>
        <Typography variant='h5'>Messages</Typography>
        {dynamic ? null : (
          <FlexHorizontal>
            <Button
              variant='outlined'
              color='default'
              endIcon={<Label />}
              onClick={() => {
                setBulkTagging(true)
              }}>
              Bulk Tag
            </Button>
            <Box width={20} />
            <CsvImportButton
              mappings={['message']}
              handleImport={async data => {
                const results = await batch({
                  batchSize: 5
                })(async (item: any) => {
                  const text = item.message.includes('[[') ? null : item.message
                  const characters = item.message.includes('[[') ? JSON.parse(item.message) : null
                  return await createMessage({
                    variables: {
                      input: {
                        text,
                        characters
                      }
                    }
                  })
                })(data)

                setMessages(messages =>
                  deduplicateMessages([
                    ...messages,
                    ...results
                      .filter(({ data }: any) => data?.createMessage.__typename === 'MessageV2')
                      .map(({ data }: any) => {
                        return data?.createMessage as IMessage
                      })
                  ])
                )
              }}
            />
            <Box width={20} />
            <Button
              variant='outlined'
              color='default'
              endIcon={<CloudDownload />}
              onClick={() => {
                const csvData = messages.map(message => `"${JSON.stringify(message.characters)}"`).join('\n')
                csvDownload(csvData)
              }}>
              Export CSV
            </Button>
            <Box width={20} />
            <Button variant='contained' onClick={() => setIsAddingMessage(true)}>
              Add Message
            </Button>
          </FlexHorizontal>
        )}
      </FlexHorizontal>
      <Spacer size='large' />
      {dynamic ? (
        <DynamicMessages messageSetId={props?.messageSet?.id || ''} />
      ) : !messages.length ? (
        <Alert severity='info'>No messages yet. Add a message to get started.</Alert>
      ) : (
        <Card>
          <InfiniteScroll
            pageStart={0}
            loadMore={async () => {
              setMessagesToShow(messagesToShow => messagesToShow + 25)
            }}
            hasMore={messages.length > messagesToShow}
            loader={
              <Box>
                <Spacer size='large' />
                <CircularProgress />
              </Box>
            }>
            <TableContainer>
              <Table>
                <TableBody component={dynamic ? TableBody : DroppableTableBody(onDragEnd)}>
                  {messages
                    .filter((_, index) => index <= messagesToShow)
                    .map((message, index) => (
                      <TableRow component={dynamic ? TableRow : DraggableTableRow(message.id, index)} key={message.id}>
                        {dynamic ? null : (
                          <TableCell style={{ width: 40 }}>
                            <DragIndicator />
                          </TableCell>
                        )}
                        <TableCell style={{ width: 200 }}>
                          {message.text ? (
                            <>
                              <Card raised>
                                <CardContent>
                                  <Typography variant='body2'>{message.text}</Typography>
                                </CardContent>
                              </Card>
                              {dynamic ? (
                                <>
                                  <Spacer size='medium' />
                                  <FormattedDateTime value={message.createdAt} />
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </TableCell>
                        <TableCell>
                          {(message.tags || []).map((tag, index) => (
                            <Chip
                              key={`${tag}-${index}`}
                              label={tag}
                              style={{
                                margin: 3
                              }}
                            />
                          ))}
                        </TableCell>

                        {dynamic ? null : (
                          <TableCell style={{ width: 250 }}>
                            <Box width={250} onClick={() => setMessageId(message.id)}>
                              <BoardPreview characters={message.characters as IBoard} />
                            </Box>
                          </TableCell>
                        )}
                        <TableCell style={{ width: 30 }}>
                          <ButtonBase
                            onClick={() => {
                              setMessages(messages =>
                                messages.filter(currentMessage => currentMessage.id !== message.id)
                              )
                            }}>
                            <RemoveCircle color='action' />
                          </ButtonBase>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </Card>
      )}
    </>
  )
}
