import { GetMessageSetV2, GetMessageSetV2Variables } from './__generated__/GetMessageSetV2'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query GetMessageSetV2($id: String!) {
    messageSet: messageSetV2(id: $id) {
      __typename
      ... on MessageSetV2 {
        id
        title
        public
        dynamic
        rssUrl
        messages: messagesV2 {
          id
          text
          characters
          createdAt
          tags
        }
      }
      ... on MessageSetErrorV2 {
        type
        error
      }
    }
  }
`

export const useGetMessageSet = (id: string) => {
  return useQuery<GetMessageSetV2, GetMessageSetV2Variables>(QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      id
    }
  })
}
